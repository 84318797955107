<template>
  <div>
    <div class="relative">
        <TextField class="pb-2"
          :inputId="'address2'"
          :showcharLimit="false"
          :inputext="location"
          :placholderText="`Search Address`"
          :lableText="'Search address'"
          :autoFocus="true"
          @inputChangeAction="(data) => location = data"  />
        <div class="card p-2 absolute z-10 bg-white w-full max-w" style="display: revert;" v-if="isSshowSelection && searchResults.length > 0" v-click-outside="closeList">
          <span v-for="(result, i) in searchResults" :key="i" @click="saveLocation(result, i)" class="  ">
            <div class="flex hover:bg-gray-100 ">
              <div class="items-center grid w-14 mr-2">
                <div class="text-center"><i class="fas fa-map-marker-alt text-primary pr-3 pl-2"></i></div>
                <div class="heading-7 text-center text-gray3">{{getDistance (parseInt(result.distance_meters))}}</div>
              </div>
              <div class="">
                <div class="cursor-pointer text-text1 font-semibold">{{result.structured_formatting.main_text}}</div>
                <div class="cursor-pointer text-text2">{{result.structured_formatting.secondary_text}}</div>
              </div>
            </div>
            <div v-if="searchResults.length !== i + 1" class="dividerLight my-1"></div>
          </span>
        </div>
    </div>
    <div class="py-4">
      <gmap-map v-if="addressObj.isShow"
        :center="addressObj.center"
        :zoom="18"
        @click='mark'
        style="width:100%;  height: 350px;">
        <gmap-marker
          :position="addressObj.center"></gmap-marker>    <!-- <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDo4Cf7V2_lPZMfNXciostEss8xtfLgRRs&libraries=places"></script> -->

          <GmapCircle
            v-for="(pin, index) in markers"
            :key="index"
            :center="pin.position"
            :radius="addressObj.selectedRadius"
            :visible="true"
            :options="{fillColor:'#ff000000',strokeColor: 'red',fillOpacity:1.0}"
          ></GmapCircle>
      </gmap-map>
    </div>
    <div v-if="addressObj.isShow" class="">
      <span v-if="addressObj.fullAddress !== ''">
        <p class="text-text2 heading-4">{{addressObj.fullAddress}}</p>
      </span>
    </div>
  </div>
</template>

<script>
/* global google */
import TextField from '@/View/components/textfield.vue'
import store from '@/store'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import  GmapCircle from 'vue2-google-maps/dist/components/circle'
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
  data: () => ({
    googleSessionToken: '',
    isSshowSelection: false,
    location: '',
    searchResults: [],
    service: null, // will reveal this later 🕵️
    addressSearch: '',
    autocompleteOptions: {
      lat: 0,
      lng: 0
    },
    placesService: null,
    markers: [],
    addressObj: {
      isShow: false,
      center: null,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      latitude: '',
      longitude: '',
      fullAddress: '',
      selectedRadius: 0,
      },
    places: [],
    currentPlace: null
  }),
  metaInfo () {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDo4Cf7V2_lPZMfNXciostEss8xtfLgRRs&libraries=places`,
        async: true,
        defer: true,
      }]
    }
  },
  components: {
    
    TextField,
    // ,
    GmapCircle,
  },
  props: ['isShowMap', 'showAdditional', 'selectedRadius', 'orgObj', 'clearSearch'],
  name: 'GoogleMap',
  created () {
  },
  watch: {
    location (newValue) {
      console.log('newValue', newValue)
      console.log('this.autocompleteOptions.lat', this.autocompleteOptions)
      setTimeout(() => {
        this.MapsInit()
        if (newValue.length > 0) {
          this.service.getPlacePredictions({
            input: this.location,
            origin: new google.maps.LatLng( this.autocompleteOptions.lat, this.autocompleteOptions.lng ),
            location: new google.maps.LatLng( this.autocompleteOptions.lat, this.autocompleteOptions.lng ),
            radius: 800
          }, this.displaySuggestions)
        } else {
          this.searchResults = []
        }
      }, 100);
    },
    clearSearch : {
      handler () {
        if (this.clearSearch) {
          this.googleSessionToken = '',
          this.isSshowSelection = false,
          this.location = '',
          this.searchResults = [],
          this.service = null, // will reveal this later 🕵️
          this.addressSearch = '',
          this.autocompleteOptions = {
            lat: 0,
            lng: 0
          },
          this.placesService = null,
          this.markers = [],
          this.addressObj = {
            isShow: false,
            center: null,
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            latitude: '',
            longitude: '',
            fullAddress: '',
            selectedRadius: 0,
            },
          this.places = [],
          this.currentPlace = null
        }
      }, 
      deep: true
    }
  },
  mounted () {
    if (this.orgObj !== undefined) {
    this.location = this.orgObj.fullAddress
    this.addressObj.center = {
      lat: this.orgObj.latitude,
      lng: this.orgObj.longitude
    }
    this.addressObj.fullAddress = this.orgObj.fullAddress
  }
    this.$root.$on("radius", (response) => {
      this.addressObj.selectedRadius = response
    })
    this.addressObj.isShow = this.isShowMap
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.autocompleteOptions = {
          lat : position.coords.latitude,
          lng : position.coords.longitude,
        }
        console.log("__________position", position);
      })
    }
    else {
    let orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
    if (orgDetail !== null) {
      this.autocompleteOptions = {
        lat : orgDetail.latitude,
        lng : orgDetail.longitude,
      }
    } else {
      this.autocompleteOptions = {
        lat : 0,
        lng : 0,
      }
    }
      // alert('Geolocation is not supported in this browser')
    }
  },
  methods: {
    getDistance (distanceMeter) {
      console.log('distanceMeter', distanceMeter)
      let distance = '';
      if (!isNaN(distanceMeter)) {
        if (navigator.languages != undefined) {
          console.log('navigator.languages', navigator.languages)
          const countryCode = navigator.languages[0];
          switch (countryCode) {
            case 'US':
              if (distanceMeter * 0.00062137 >= 1000) {
                distance = `999+ mi`;
              } else {
                distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
              }
              break;
            default:
              if (distanceMeter / 1000 >= 1000) {
                distance = `999+ km`;
              } else {
                distance = `${(distanceMeter / 1000).toFixed(1)} km`;
              }
            break;
          }
          return distance;
        } else {
          if (distanceMeter / 1000 >= 1000) {
            distance = `999+ km`;
          } else {
            distance = `${(distanceMeter / 1000).toFixed(1)} km`;
          }
          return distance;
        }
      } else {
        return '--'
      }
    },
    closeList () {
      this.isSshowSelection = false
    },
    async saveLocation (data, i) {
      this.isSshowSelection = false
      this.searchResults = []
      console.log('data 123', data, i)
      this.placesService = new window.google.maps.places.PlacesService(
        new window.google.maps.Map(document.createElement('div'))
      )
      try {
        const placeDetails = await this.getPlaceDetails(data)
        if (placeDetails !== undefined) {
          this.setPlace(placeDetails)
        }
        console.log('123 12 3123 12 3123 12 312 3123 ', placeDetails)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    getPlaceDetails (choice) {
      console.log('choice', choice)
      return new Promise((resolve, reject) => {
        this.placesService.getDetails({
          placeId: choice.place_id,
        },
        (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            return resolve(result)
          }
          return reject(result)
        })
      })
    },
    MapsInit () {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions (predictions, status) {
      console.log('this.predictions', predictions)
      console.log('this.status', status)
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        this.isSshowSelection = false
        return
      }
      this.searchResults = predictions.map(prediction => prediction) 
      console.log('this.searchResults', this.searchResults)
      this.isSshowSelection = true
    },
    mark (event) {
      let newAddress = ''
      var geocoder = new google.maps.Geocoder;
      var latlng = {lat: event.latLng.lat(), lng: event.latLng.lng()};
      geocoder.geocode({'location': latlng}, function(results, status) {
        if (status === 'OK') {
          if (results[1]) {
            newAddress = results[1].formatted_address
          } else {
            window.alert('No results found');
          }
        }
      })
      this.addressObj.center = {
        lat: latlng.lat,
        lng: latlng.lng
      }
      this.markers = []
      if (this.showAdditional) {
        this.markers.push({ position: this.addressObj.center })
      }
      setTimeout(() => {
        this.addressObj.fullAddress = newAddress
        this.location = newAddress
        this.addressObj.latitude = event.latLng.lat()
        this.addressObj.longitude = event.latLng.lng()
        this.$root.$emit('GlobalAddressObject', this.addressObj)
      }, 500);
    },
    setPlace (place) {
      let places= []
      if (place) {
        this.markers = []
        this.addressObj.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }
        if (this.showAdditional) {
          this.markers.push({ position: this.addressObj.center })
        }
        places.push(place)
      }
      this.addressObj.isShow = true
      let detailData = places
      this.addressObj.latitude = place.geometry.location.lat()
      this.addressObj.longitude = place.geometry.location.lng()
      this.addressObj.street = ''
      this.addressObj.state = ''
      this.addressObj.country = ''
      this.addressObj.zip = ''
      this.addressObj.city = ''
      let mapAddressDetails = []
      let type = ''
      for (let i = 0; i < detailData.length; i++) {
        mapAddressDetails = detailData[i].address_components
        for (let j = 0; j < mapAddressDetails.length; j++) {
          type = mapAddressDetails[j].types[0]
          if (type === 'street_number' || type === 'sublocality_level_2' || type === 'sublocality' || type === 'sublocality_level_1' || type === 'route') {
            this.addressObj.street += ' ' + mapAddressDetails[j].long_name
          }
          if (type === 'administrative_area_level_1') {
            this.addressObj.state = mapAddressDetails[j].short_name
          }
          if (type === 'administrative_area_level_2') {
            this.addressObj.country = mapAddressDetails[j].long_name
          }
          if (type === 'postal_code') {
            this.addressObj.zip = mapAddressDetails[j].long_name
          }

          if (type === 'locality') {
            this.addressObj.city = mapAddressDetails[j].long_name
          }
        }
      }
      this.addressObj.addressLine1 = place.name
      let space = place.name !== '' ? ', ' : ''
      this.addressObj.fullAddress = place.name + space + place.formatted_address
      this.location = this.addressObj.fullAddress
      this.$root.$emit('GlobalAddressObject', this.addressObj)
    },
    matchFind (str) {
      if (this.location === null || this.location === undefined || this.location === ' ') {
        return
      }
      let searchStrArray = this.location.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<span style="font-weight: 700;color: #2D2D2D">' + match + '</span>'
          })
        }
      }
      return replceString
    }
  }
}
</script>
<style scoped>
</style>
