<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" style="width: 900px;max-height: 90%;">
    <div class="relative" >
      <div class="bg-primary flex justify-between p-3 sticky top-0">
        <div class=" font-medium text-white heading-3">
          Select Location
        </div>
      </div>
      <div style="max-height: 80vh;min-height: 40vh;" class="overflow-auto">
        <div class="p-4">
          <GoogleMap :isShowMap="showMap" :orgObj="addressObj" >
          </GoogleMap>
        </div>
      </div>
      <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
        <div class="text-rigth text-text1 flex gap-2 my-3">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
          <Button :btnSize="'medium'" :disabled="center === null" :textColor="'white'" :btnColor="'success'" :btnText="`Save`" @buttonAction="saveNewAddress()"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import GoogleMap from '@/View/components/GoogleMap.vue'
import Button from '@/View/components/globalButton.vue'
import store from '@/store'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import MyJobApp from '@/api/MyJobApp.js'
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
components: {
  Button,
  GoogleMap
},
data () {
  return {
    showMap: false,
    autocompleteOptions: {
        lat: 0,
        lng: 0
      },
    addressObj: {
      latitude: '',
      longitude: '',
      fullAddress: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      },
      center: null,
      currentLoc: null,
      value1123: '',
  }
},
props: ['detail'],
created() {
},
beforeMount () {
  console.log('detail', this.detail);
  let orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
  if (orgDetail !== null) {
    if (orgDetail.latitude !== 0 && orgDetail.longitude !== 0) {
      this.center = { lat: orgDetail.latitude, lng: orgDetail.longitude}
      this.addressObj.latitude = orgDetail.latitude
      this.addressObj.longitude = orgDetail.longitude
      this.addressObj.fullAddress = this.detail.fullAddress
      this.addressObj.addressLine1 = this.detail.addressLine1
      this.addressObj.addressLine2 = this.detail.addressLine2
      this.addressObj.city = this.detail.city
      this.addressObj.state = this.detail.state
      this.addressObj.zip = this.detail.zip
      this.addressObj.country = this.detail.country
      this.showMap = true
    }
  } else {
    this.center = { lat: 0, lng: 0}
  }
},
mounted () {
  this.$root.$on("GlobalAddressObject", (response) => {
    this.addressObj.latitude = response.latitude
    this.addressObj.longitude = response.longitude
    this.addressObj.fullAddress = response.fullAddress
    this.center = { lat: response.latitude, lng: response.longitude}
    this.addressObj.addressLine1 = response.addressLine1
    this.addressObj.addressLine2 = response.addressLine2
    this.addressObj.city = response.city
    this.addressObj.state = response.state
    this.addressObj.zip = response.zip
    this.addressObj.country = response.country
  })
  document.body.style = 'overflow: hidden;'
  setTimeout(() => {
    document.getElementById('auto-complete-address').focus()
  }, 500);
  let orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
  if (orgDetail !== null) {
    if (orgDetail.latitude !== 0 && orgDetail.longitude !== 0) {
      this.center = { lat: orgDetail.latitude, lng: orgDetail.longitude}
      this.addressObj.fullAddress = this.detail.fullAddress
    }
  } else {
    this.center = { lat: 0, lng: 0}
  }

  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(currentPosition => {
        this.autocompleteOptions = {
          lat : currentPosition.coords.latitude,
          lng : currentPosition.coords.longitude,
        }
      })
    }
    else {
      alert('Geolocation is not supported in this browser')
    }

},
watch: {},
methods: {
  discard () {
    document.body.style = 'overflow: visible;'
    this.$root.$emit('EditAddressHandler', false, null)
  },
  saveNewAddress () {
    MyJobApp.latLongUpdateWeb(
      this.detail.organizationDetailId,
      this.addressObj.latitude,
      this.addressObj.longitude,
      this.addressObj.fullAddress,
      this.addressObj.addressLine1,
      this.addressObj.addressLine2,
      this.addressObj.city,
      this.addressObj.state,
      this.addressObj.zip,
      this.addressObj.country,
          (response) => {
            let data = {
              latitude: this.addressObj.latitude,
              longitude: this.addressObj.longitude
            }
            localStorage.setItem('orgInfo', JSON.stringify(data))
            document.body.style = 'overflow: visible;'
            this.$root.$emit('EditAddressHandler', true, null)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
  },
},
beforeDestroy () {
  this.$root.$off('GlobalAddressObject')
}
}
</script>
<style scoped>

</style>