<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" style="width: 900px; overflow: auto; max-height: 90%;">
    <div class="relative" >
      <div>
        <div class="bg-primary flex justify-between p-3">
          <div class=" font-medium text-white heading-3">
            Quotation/Invoice Address
          </div>
        </div>
        <div class="p-4">
          <div class=" sm:grid grid-cols-12 gap-4">
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'address1'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="sendObj.addressLine1"
                :fieldError="errObj.address1Err !== ''"
                :placholderText="`Address Line1`"
                :lableText="'Address Line1'"
                :autoFocus="false"
                @keyPressAction="errObj.address1Err = ''"
                @inputChangeAction="(data) => sendObj.addressLine1 = data"  />

                <p class="text-error heading-7">{{errObj.address1Err}}</p>
            </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'address2'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="sendObj.addressLine2"
                :placholderText="`Address Line2`"
                :lableText="'Address Line2'"
                :autoFocus="false"
                :fieldError="errObj.addressLine2Err !== ''"
                @keyPressAction="errObj.addressLine2Err = ''"
                @blurAction="sendObj.addressLine2 !== '' ? errObj.address2Err = '' : null"
                @inputChangeAction="(data) => sendObj.addressLine2 = data"  />
                <p class="text-error heading-7">{{errObj.address2Err}}</p>
            </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'cityVal'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="sendObj.city"
                :placholderText="`City`"
                :lableText="'City'"
                :autoFocus="false"
                :fieldError="errObj.cityErr !== ''"
                @keyPressAction="errObj.cityErr = ''"
                @blurAction="sendObj.city !== '' ? errObj.cityErr = '' : null"
                @inputChangeAction="(data) => sendObj.city = data"  />
                <p class="text-error heading-7">{{errObj.cityErr}}</p>
            </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'stateVal'"
                :textMaxlength="20"
                :showcharLimit="true"
                :inputext="sendObj.state"
                :placholderText="`State`"
                :lableText="'State'"
                :autoFocus="false"
                :fieldError="errObj.stateErr !== ''"
                @keyPressAction="errObj.stateErr = ''"
                @blurAction="sendObj.state !== '' ? errObj.stateErr = '' : null"
                @inputChangeAction="(data) => sendObj.state = data"  />
                <p class="text-error heading-7">{{errObj.stateErr}}</p>
            </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'zipVal'"
                :textMaxlength="11"
                :showcharLimit="true"
                :inputext="sendObj.zip"
                :placholderText="`Zip`"
                :lableText="'Zip'"
                :autoFocus="false"
                :fieldError="errObj.zipErr !== ''"
                @keyPressAction="errObj.zipErr = ''"
                @blurAction="sendObj.zip !== '' ? errObj.zipErr = '' : null"
                @inputChangeAction="(data) => sendObj.zip = data"  />
                <p class="text-error heading-7">{{errObj.zipErr}}</p>
            </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'countryVal'"
                :textMaxlength="30"
                :showcharLimit="true"
                :inputext="sendObj.country"
                :placholderText="`Country`"
                :lableText="'Country'"
                :autoFocus="false"
                :fieldError="errObj.countryErr !== ''"
                @keyPressAction="errObj.countryErr = ''"
                @blurAction="sendObj.country !== '' ? errObj.countryErr = '' : null"
                @inputChangeAction="(data) => sendObj.country = data"  />
                <p class="text-error heading-7">{{errObj.countryErr}}</p>
            </div>
          </div>
        </div>
      </div>
        <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
          <div class="text-rigth flex gap-2 my-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="`Save`" @buttonAction="saveNewAddress()"/>
          </div>
          </div>
      </div>
  </div>
</div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import MyJobApp from '@/api/MyJobApp.js'
import TextField from '@/View/components/textfield.vue'
export default {
components: {
  Button,
  TextField,
},
data () {
  return {
    sendObj: {
      organizationDetailId: 0,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    errObj: {
        address1Err: '',
        addressLine2Err: '',
        cityErr: '',
        stateErr: '',
        zipErr: '',
        countryErr: '',
    }
  }
},
props: ['detail'],
 created() {
},    
mounted () {
  this.sendObj.addressLine1 = this.detail.addressLine1
  this.sendObj.addressLine2 = this.detail.addressLine2
  this.sendObj.city = this.detail.city
  this.sendObj.state = this.detail.state
  this.sendObj.zip = this.detail.zip
  this.sendObj.country = this.detail.country
  document.body.style = 'overflow: hidden;'

},
watch: {},
methods: {
  discard () {
    document.body.style = 'overflow: visible;'
    this.$root.$emit('editQIAddressHandler', false, null)
  },
  saveNewAddress () {
    let isValid = true
    if (this.sendObj.addressLine1.trim() === '') {
        this.errObj.address1Err = "Address line1 is required"
        isValid = false
    }
    if (this.sendObj.addressLine2.trim() === '') {
        this.errObj.addressLine2Err = "Address line2 is required"
        isValid = false
    }
    if (this.sendObj.city.trim() === '') {
        this.errObj.cityErr = "City is required"
        isValid = false
    }
    if (this.sendObj.state.trim() === '') {
        this.errObj.stateErr = "State is required"
        isValid = false
    }
    if (this.sendObj.zip.trim() === '') {
        this.errObj.zipErr = "Zip is required"
        isValid = false
    }
    if (this.sendObj.country.trim() === '') {
        this.errObj.countryErr = "Country is required"
        isValid = false
    }
    if (isValid) {
        MyJobApp.addressUpdateWeb(
        {
          organizationDetailId: this.detail.organizationDetailId,
          addressLine1: this.sendObj.addressLine1,
          addressLine2: this.sendObj.addressLine2,
          city: this.sendObj.city,
          state: this.sendObj.state,
          zip: this.sendObj.zip,
          country: this.sendObj.country
        },
          () => {
            document.body.style = 'overflow: visible;'
            this.$root.$emit('editQIAddressHandler', true, null)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: 'Organization Quotation/Invoice address updated', color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    }
     
  },
},
beforeDestroy () {
  // this.$root.$off('closeProfileDialog')
}
}
</script>
<style scoped>

</style>