<template>
  <div v-if="orgDetail !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Organization</h1>
        <div class="absolute right-7 flex items-center gap-3">
          <div class=" text-error heading-4 cursor-pointer bg-white px-3 py-1 rounded-xl" @click="removeConfirm = true">
            <i class="fa-solid fa-trash"></i>
          </div>
          <div class=" text-primary heading-4 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="editOrg()">
            <i class="fa-solid fa-pen-to-square"></i>
          </div>
        </div>
      </div>
    </div>
    
    <div class="flex justify-center" >
      
        <div class=" sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class="w-full flex mt-4 justify-center">
              <div v-if="orgDetail.thumbPath !== ''" class="text-center">
                  <img style="height: auto;width: 120px;" :src="`${orgDetail.pathPrefix}${orgDetail.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                </div>
              <div v-else class="text-center">
                  <img style="height: auto;width: 120px;" src="@/assets/images/companyLogo.png" class="scroll-img-view">                        
                </div>
              </div>
            <div class="flex text-gray3 mb-4  card rounded-xl bg-white p-4 my-4">
              <div>
                <p>Organization Name:</p>
                <p class="text-text2 pl-2" v-if="orgDetail.orgName !== ''">{{orgDetail.orgName}}</p>
                <p class="text-text2 pl-2" v-else>-</p>
              </div>
            </div>
            <div class="flex text-gray3 mb-4  card rounded-xl bg-white p-4 my-4">
              <div>
                <p>Tax/Code:</p>
                <p class="text-text2 pl-2" v-if="orgDetail.taxNumber !== ''">{{orgDetail.taxNumber}}</p>
                <p class="text-text2 pl-2" v-else>-</p>
              </div>
            </div>
            <div class="flex text-gray3 mb-4  card rounded-xl bg-white p-4 my-4 justify-between">
              <div>
                <p>Organization Location (Used for calculating distance):</p>
                  <p class="text-text2 pl-2" v-if="orgDetail.fullAddress !== ''">
                  {{orgDetail.fullAddress}}
                  </p>
                  <p class="text-text2 pl-2" v-else>
                    -
                  </p>
              </div>
              <div class=" text-primary heading-4 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="editAddress()" v-if="activeUer.isOwner">
                <i class="fa-solid fa-pen-to-square"></i>
              </div>
            </div>
            <div class="flex text-gray3 mb-4  card rounded-xl bg-white p-4 my-4 justify-between">
              <div>
                <p>Quotation/Invoice Address:</p>
                  <p class="text-text2 pl-2">
                  {{orgDetail.addressLine1 !== '' ? orgDetail.addressLine1 +',' : ''}} {{orgDetail.addressLine2 !== '' ? orgDetail.addressLine2 + ',' : ''}}
                  </p>
                  <p class="text-text2 pl-2">
                    {{orgDetail.city !== '' ? orgDetail.city + ',' : ''}} {{orgDetail.state}} 
                  </p>
                  <p v-if="orgDetail.country !== ''" class="text-text2 pl-2">
                    {{orgDetail.country !== '' ? orgDetail.country + ',' : ''}} {{ orgDetail.zip }}
                  </p>
                  <p class="text-text2 pl-2" v-if="orgDetail.addressLine1 === '' && orgDetail.addressLine2 === '' && orgDetail.city === '' && orgDetail.state === '' && orgDetail.country === '' && orgDetail.zip === ''">
                    -
                  </p>
              </div>
              <div class=" text-primary heading-4 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="editQtInvAddressPopup = true" v-if="activeUer.isOwner">
                <i class="fa-solid fa-pen-to-square"></i>
              </div>
            </div>
            <div class="flex text-gray3 mb-4  card rounded-xl bg-white p-4 my-4">
              <div>
                <p>Time Zone:</p>
                <p class="text-text2 pl-2" v-if="orgDetail.timeZone !== ''">{{orgDetail.timeZone}}</p>
                <p class="text-text2 pl-2" v-else>-</p>
              </div>
            </div>
            <!-- <div class="flex text-gray3 mb-4  card rounded-xl bg-white p-4 my-4">
              <div>
                <p>Organization Code <span class="heading-6">(Required for employee sign up)</span>:</p>
                <p class="text-text2 pl-2" v-if="orgDetail.orgCode !== ''"><span ref="message">{{orgDetail.orgCode}}</span> <span class="text-primary heading-6 cursor-pointer pl-1" @click="copyTextNoInput()">(Copy)</span></p>
                <p class="text-text2 pl-2" v-else>-</p>
              </div>
            </div> -->
            <div class="flex text-gray3 mb-4  card rounded-xl bg-white p-4 my-4">
              <div>
                <p>Notes for Invoice:</p>
                <!-- <p class="text-text2 pl-2 whitespace-pre-wrap" v-if="orgDetail.notes !== ''">{{orgDetail.notes}}</p> -->
                <div class="mb-0 whitespace-pre-line pl-2" v-if="orgDetail.notes !== ''">
                {{orgDetail.notes === '' ? '-' : (orgDetail.notes.length > 300)? readMoreNote ?  orgDetail.notes : orgDetail.notes.substr(0, 300) : orgDetail.notes}}
                <span v-if="orgDetail.notes.length > 300" class="text-primary cursor-pointer heading-7" @click="readMoreNote = !readMoreNote">
                 {{readMoreNote === false ? 'Read More...' : 'Read Less...'}}</span>
                </div>
                <p class="text-text2 pl-2" v-else>-</p>
              </div>
            </div>
        </div>
    </div>
    <!-- <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" /> -->
    <ConfirmBox v-if="removeConfirm" :yesConfir="true" :inputLable='`Type "Yes" in below text box to delete Organization`'  :message="'Deleting account will delete all organization data including employee, jobs, customers, lead, invoice, etc..'" :title="'Delete Account'" />
    <EditOrg v-if="editOrgPopup" :detail="orgDetail" />
    <EditAddress v-if="editAddressPopup" :detail="orgDetail" />
    <QuatationInvoiceAddr v-if="editQtInvAddressPopup" :detail="orgDetail" />
  </div>
</template>
<script>
import EditAddress from '@/View/organization/components/AddressAdd.vue'
import QuatationInvoiceAddr from '@/View/organization/components/QuatationInvoiceAddr.vue'
import EditOrg from '@/View/organization/updateOrg.vue'
import MyJobApp from "@/api/MyJobApp.js";
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import {removeCookies} from '@/utils/cookies'
export default {
  name: "customer",
  components: {
    EditOrg,
    EditAddress,
    ConfirmBox,
    QuatationInvoiceAddr
  },
  data() {
    return {
      readMoreNote: false,
      editAddressPopup: false,
      editQtInvAddressPopup: false,
      editOrgPopup: false,
      removeConfirm: false,
        orgDetail: null,
        activeUer: {},
        titleObj: [
          {title: 'Invoice Number', icon: '', sortName: ''},
          {title: 'Invoice Total', icon: '', sortName: ''},
          {title: 'Date', icon: '', sortName: ''},
          {title: 'Due Date', icon: '', sortName: ''},
          {title: 'Amount', icon: '', sortName: ''},
          {title: 'Status', icon: '', sortName: ''},
        ],
    };
  },
  created() {},
  mounted() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.$root.$on('EditAddressHandler', (response) => {
      document.body.style = 'overflow: visible;'
      if (response) {
        this.getOrgDetail()
      }
      this.editAddressPopup = false
    })
    this.$root.$on('editQIAddressHandler', (response) => {
      document.body.style = 'overflow: visible;'
      if (response) {
        this.getOrgDetail()
      }
      this.editQtInvAddressPopup = false
    })
    this.getOrgDetail()
     this.$root.$on('closeEditPopup', (response) => {
      document.body.style = 'overflow: visible;'
      if (response) {
        this.getOrgDetail()
        this.editOrgPopup = false
      }
      this.editOrgPopup = false
    })
    this.$root.$on('confirmBoxHandler', (data) => {
      document.body.style = 'overflow: visible;'
      if (data && this.removeConfirm) {
        this.deleteOrganiFinal()
      }
      this.removeConfirm = false
    })
  },
  methods: {
    deleteOrganiFinal() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.orgnizationDelete(
        (response) => {
            document.body.style = 'overflow: visible;'
            removeCookies('jobAuthToken')
            localStorage.clear()
            this.$router.push({name: 'login'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            console.log('error', error);
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    copyTextNoInput() {
      this.selectText(this.$refs.message)
      document.execCommand('copy')
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'Organization code copied to clipboard', color: 'success'})
    },
    selectText (element) {
      var range
      if (document.selection) {
        range = document.body.createTextRange()
        range.moveToElementText(element)
        range.select()
      } else if (window.getSelection) {
        range = document.createRange()
        range.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
    },
    editAddress () {
      this.editAddressPopup = true
    },
    editOrg () {
      this.editOrgPopup = true
    },
    getOrgDetail() {
      localStorage.removeItem('orgInfo')
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
      MyJobApp.getOrgDetail(
        (response) => {
          this.orgDetail = response.Data
          setTimeout(() => {
            let latLong = {
              latitude: response.Data.latitude,
              longitude: response.Data.longitude
            }
            localStorage.setItem('orgInfo', JSON.stringify(latLong))
          }, 100);
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  },
  beforeDestroy() {
    this.$root.$off("EditAddressHandler");
    this.$root.$off("closeEditPopup");
    this.$root.$off("editQIAddressHandler");
  },
};
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.name_col {
    min-width: 200px;
    max-block-size: 200px;
}
.des_col {
    min-width: 250px;
    max-block-size: 250px;
}
.pric_col {
    min-width: 100px;
    max-block-size: 100px;
}
.qnty_col {
    min-width: 100px;
    max-block-size: 100px;
}
.amt_col {
    min-width: 100px;
    max-block-size: 100px;
}
</style>